import { vnModule_knockoutJS } from "../../vendor/valnet/valnet_jslib/vnApp/vnModule_knockoutJS";
import { XMLHttpRequestHandler } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { XMLHttpRequestHandler_requestType } from "../../vendor/valnet/valnet_jslib/XMLHttpRequestHandler/XMLHttpRequestHandler";
import { vn_app_zone_notification } from "../../vendor/valnet/valnet_jslib/vnApp/vn_app_zone_notification";
import { adsBehaviourByBrand_template } from "../components/templates/adsBehaviourByBrand_template";
import {header} from "./header";

declare var ko: any;
declare var page: any;

export class adsBehaviourByBrand extends vnModule_knockoutJS{

    protected XHRHdl: XMLHttpRequestHandler;
    protected adsBehaviourByBrand_template: adsBehaviourByBrand_template;

    /**
     * @inheritDoc
     */
    protected getModuleName(){

        return "adsBehaviourByBrand";
    }

    /**
     * @inheritDoc
     */
    public buildContent(): Array<Element>{
        
        let zoneHeader = this.getVnAppZone().app.getZoneByName("Header");
        let loadedModule = zoneHeader.loadedModule;
        (<header>loadedModule).setHeaderTitle("Ad Behaviour By Brand");

        // create the users form
        this.adsBehaviourByBrand_template = new adsBehaviourByBrand_template("adsBehaviourByBrand_template", this.viewModel, this);

        return [this.adsBehaviourByBrand_template.build()];
    }

    /**
     * @inheritDoc
     */
    public fillPage(): void{
        this.getReferences();
    }

    /**
     * Hook.
     *
     * Saves the edits to a user.
     */
    protected saveAdsBehaviourByBrand(): void{

        this.save();
    }

    /**
     * Hook.
     *
     * Cancels the edits to a user.
     */
    protected cancelAdsBehaviourByBrand(){

        this.loadingIn(this.adsBehaviourByBrand_template.builtTemplate);

        this.releaseLock();

        let idBrand = this.getVnAppZone().app.params.idBrand;

        let url = `/configurations`
        if (idBrand){
            url = `/brand/${idBrand}/ads_setups`
        }

        // Redirect to earnings page or user list.
        this.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_WARNING, "Ad behaviour action canceled!");

        page.redirect(url);
    }

    /**
     * Requests a lock release.
     */
    protected releaseLock(): void{

        let formReq_params: Array<Array<string>> = [];

        // Request a lock release.
        this.XHRHdl = new XMLHttpRequestHandler(`/api/brand/${this.getVnAppZone().app.params.idBrand}/ads_behaviour/${this.getVnAppZone().app.params.id}/release_lock/`, formReq_params, this);
        this.XHRHdl.execute();
    }


    /**
     * Obtain the dynamic data required to fill the form.
     */
    protected getEditData(takeover: boolean = false): void{

        let idBrand = this.getVnAppZone().app.params.idBrand;
        
        let url     = '';
        if(idBrand){
            url = `/api/brand/${idBrand}/ads_behaviour/get`
        }
        else{
            url = `/api/ads_behaviour/get`
        }
        
        this.XHRHdl = new XMLHttpRequestHandler(url, [['takeover', (takeover) ? '1' : '0']], this);
        this.XHRHdl.onReadyStateFunction = this.onEditDataRequestReturn;
        this.XHRHdl.execute();

    }

    /**
     * Handles the form data response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onEditDataRequestReturn(req, obj): Function{

        return function(){

            obj.manageResponse(req, obj, function(){

                let responseParsed = JSON.parse(req.request.response);
                obj.updateEditData(responseParsed);
            });
        }
    }

    protected getReferences(): void{

        let idBrand = this.getVnAppZone().app.params.idBrand;
        
        let url     = '';
        if(idBrand){
            url = `/api/brand/${idBrand}/ads_behaviour/references`
        }
        else{
            url = `/api/ads_behaviour/references`
        }
        
        this.loadingIn(this.adsBehaviourByBrand_template.builtTemplate);
        this.XHRHdl = new XMLHttpRequestHandler(url, [], this);
        this.XHRHdl.onReadyStateFunction = this.onReferencesRequestReturn;
        this.XHRHdl.execute();
    }

    protected onReferencesRequestReturn(req, obj): Function{
        return function(){

            obj.manageResponse(req, obj, function(){
                
                let responseParsed = JSON.parse(req.request.response);
                obj.setZones(responseParsed.zones);
                obj.loadingOut(obj.adsBehaviourByBrand_template.builtTemplate);

                if (responseParsed.allZones != undefined){ // this will be undefined if we are in configuration
                    obj.setDefaultZones(responseParsed.allZones);
                }
                if (responseParsed.globalAdsBehaviour != undefined){ // this will be undefined if we are in configuration
                    obj.setGlobalAdsBehaviour(responseParsed.globalAdsBehaviour);
                }


                obj.getEditData();
            });
        }
    }

    /**
     * Updates the form elements.
     *
     */
    protected updateEditData(responseParsed: any){
        
        this.setAdsBehaviourByBrand(responseParsed.data);
    }

    protected setAdsBehaviourByBrand(adsBehaviourByBrand){
        this.adsBehaviourByBrand_template.setAdsBehaviourByBrand(adsBehaviourByBrand);
    }

    protected setZones(zones){
        this.adsBehaviourByBrand_template.setZones(zones);
    }    

    protected setGlobalAdsBehaviour(global){
        this.adsBehaviourByBrand_template.setGlobalAdsBehaviour(global || null);
    }

    protected setDefaultZones(zones){
        this.adsBehaviourByBrand_template.setDefaultZones(zones);
    }

    /**
     * Saves the user data.
     */
    protected save(): void{

        let idBrand = this.getVnAppZone().app.params.idBrand;
        
        let url     = '';
        if(idBrand){
            url = `/api/brand/${idBrand}/ads_behaviour/save`
        }
        else{
            url = `/api/ads_behaviour/save`
        }

        this.loadingIn(this.adsBehaviourByBrand_template.builtTemplate);

        let formReq_params: Array<Array<string>> = [];

        let subViewModel = this.adsBehaviourByBrand_template.getViewModelForRequest();

        formReq_params.push(["data", ko.toJSON(subViewModel)] );

        let XHRHdl:XMLHttpRequestHandler = new XMLHttpRequestHandler(url, formReq_params, this);
        XHRHdl.mode                      = XMLHttpRequestHandler_requestType.POST;
        XHRHdl.onReadyStateFunction      = this.onSaveReturn;
        XHRHdl.execute();
    }

    /**
     * Handles save response.
     *
     * @param req
     * @param obj
     * @return {function(): void}
     */
    protected onSaveReturn(req, obj): Function{
        return function(){

            let idBrand = obj.getVnAppZone().app.params.idBrand;
        
            let url     = '';
            if(idBrand){
                url = `/brand/${obj.getVnAppZone().app.params.idBrand}/ads_setups/`
            }
            else{
                url = `/configurations`
            }

            obj.manageResponse(req, obj, function(){

                obj.getVnAppZone().notification = new vn_app_zone_notification(vn_app_zone_notification.TYPE_SUCCESS, 'Ad behaviour saved successfully!');
                obj.releaseLock();
                page.redirect(url);
            });

            obj.loadingOut(obj.adsBehaviourByBrand_template.builtTemplate);
        }
    }

    /** @inheritDoc */
    public confirmationModalOnClickConfirm(context, data): void{

        this.getEditData(true);
    }

    /** @inheritDoc */
    public confirmationModalOnClickClose(context, data): void{

        page.redirect(data);
    }

    public filterMultipleSelectorOnChange(): void {
        //needed for multiple selector
    }
}
