import { multipleSelector_template } from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/multipleSelector_template";
import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";

declare var ko: any;

export class adsBehaviourByBrand_template extends vnTemplate{

    protected pageLoadZones: multipleSelector_template;

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="form-header">
                <h3 class="form-title icon i-config">Edit Ads Behaviour</h3>

                <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close-2" type="button"></button>
            </div>
      
            <div class="w-form-body">
                <div class="form-body" data-bind="css: { 'full-width' : !showGlobalAdsBehaviour() }" style="text-align: left">
                    <div id="selected-zones" style="display:none"></div>
                    <div class="sub-header" style="padding-top: 0;">
                        <label class="form-label form-label-box multi">Zones on page load</label>
                        ` + this.pageLoadZones.getHtmlBuild() + `
                    </div>
                </div>
                <!-- ko if: showGlobalAdsBehaviour -->
                <div class="form-body">
                    <div class="form-group">
                        <label class="form-sub-title">Default Zones</label>
                        <ul class="ads-behaviour-list" data-bind="foreach: selectedDefaultZones">
                            <li data-bind="text: name"></li>
                        </ul>
                    </div>
                </div>
                <!-- /ko -->    
                
            </div>
            
            <div class="w-form-body ads-behaviour-data">
            
                <div class="form-body">
                    
                    <div class="form-group">
                        <label class="form-sub-title">Prebid Floors</label>
                    </div>

                    <!-- ko foreach: prebidFloors -->

                    <div class="form-group row">
                        <label class="form-label" data-bind="text: key, attr: {for: 'prebidFloors_' + key }"></label>
                        <!-- ko if: $parent.showGlobalAdsBehaviour -->
                            <input data-bind="value: globalValue" type="number" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input class="input-text num" style="width:15%; margin : 0 5px" type="number" data-bind="value: value, attr: {id: 'prebidFloors_' + key, name: 'prebidFloors_' + key }">
                    </div>      

                    <!-- /ko -->
                
                    <div class="form-group">
                        <label class="form-sub-title">Lazy Loading</label>
                    </div>
                    
                    <div class="form-group row">
                        <label for="pixelDistanceAuctionOtherZones" class="form-label">Pixel distance for other zones <span style="color:white" class="form-label legend"> (px) </span></label>
                        <!-- ko if: showGlobalAdsBehaviour -->
                        <input data-bind="value: globalAdsBehaviour().pixelDistanceAuctionOtherZones" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="pixelDistanceAuctionOtherZones" data-bind="value: pixelDistanceAuctionOtherZones" type="text" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>                       
                    <div class="form-group row">
                        <label for="viewportPercentageGamRequestDesktop" class="form-label">Viewport percentage GAM Request Desktop <span style="color:white" class="form-label legend"> (%) </span></label>
                        <!-- ko if: showGlobalAdsBehaviour -->
                        <input data-bind="value: globalAdsBehaviour().viewportPercentageGamRequestDesktop" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="viewportPercentageGamRequestDesktop" data-bind="value: viewportPercentageGamRequestDesktop" type="text" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>                           
                    <div class="form-group row">
                        <label for="viewportPercentageGamRequestMobile" class="form-label">Viewport percentage GAM Request Mobile <span style="color:white" class="form-label legend"> (%) </span></label>
                        <!-- ko if: showGlobalAdsBehaviour -->
                        <input data-bind="value: globalAdsBehaviour().viewportPercentageGamRequestMobile" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="viewportPercentageGamRequestMobile" data-bind="value: viewportPercentageGamRequestMobile" type="text" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>                       
                    <div class="form-group row">
                        <label for="viewportPercentageGamRenderDesktop" class="form-label">Viewport percentage GAM Render Desktop <span style="color:white" class="form-label legend"> (%) </span></label>
                        <!-- ko if: showGlobalAdsBehaviour -->
                        <input data-bind="value: globalAdsBehaviour().viewportPercentageGamRenderDesktop" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="viewportPercentageGamRenderDesktop" data-bind="value: viewportPercentageGamRenderDesktop" type="text" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>                    
                    <div class="form-group row">
                        <label for="viewportPercentageGamRenderMobile" class="form-label">Viewport percentage GAM Render Mobile <span style="color:white" class="form-label legend"> (%) </span></label>
                        <!-- ko if: showGlobalAdsBehaviour -->
                        <input data-bind="value: globalAdsBehaviour().viewportPercentageGamRenderMobile" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="viewportPercentageGamRenderMobile" data-bind="value: viewportPercentageGamRenderMobile" type="text" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>
                    
                    <div class="form-group">
                        <label class="form-sub-title">Refresh Logic Defaults </label>
                    </div>

                    <div class="form-group row">
                        <label for="refreshLogicRefreshTime" class="form-label">Refresh time <span style="color:white" class="form-label legend"> (sec) </span></label>
                        <!-- ko if: showGlobalAdsBehaviour -->
                        <input data-bind="value: globalAdsBehaviour().refreshLogicRefreshTime" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="refreshLogicRefreshTime" data-bind="value: refreshLogicRefreshTime" type="text" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>   
                    <div class="form-group row">
                        <label for="refreshLogicMinBufferTime" class="form-label">Minimum buffer time <span style="color:white" class="form-label legend"> (sec) </span></label>
                        <!-- ko if: showGlobalAdsBehaviour -->
                        <input data-bind="value: globalAdsBehaviour().refreshLogicMinBufferTime" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="refreshLogicMinBufferTime" data-bind="value: refreshLogicMinBufferTime" type="text" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>   
                    <div class="form-group row">
                        <input id="refreshLogicPrefetch" type="checkbox" data-bind="checked: refreshLogicPrefetch, attr: {'name': 'refreshLogicPrefetch' }">
                        <label for="refreshLogicPrefetch" class="form-label icon i-check">Prefetch</label>
                    </div>                    
                    <div class="form-group row">
                        <label for="requestDelayAdErrorOutstream" class="form-label">Delay ad error outstream <span style="color:white" class="form-label legend"> (ms) </span></label>
                        <!-- ko if: showGlobalAdsBehaviour -->
                        <input data-bind="value: globalAdsBehaviour().requestDelayAdErrorOutstream" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="requestDelayAdErrorOutstream" data-bind="value: requestDelayAdErrorOutstream" type="text" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>                         
                    <div class="form-group row">
                        <label for="requestDelayNoAdFillOutstream" class="form-label">Delay no ad fill outstream <span style="color:white" class="form-label legend"> (ms) </span></label>
                        <!-- ko if: showGlobalAdsBehaviour -->
                        <input data-bind="value: globalAdsBehaviour().requestDelayNoAdFillOutstream" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="requestDelayNoAdFillOutstream" data-bind="value: requestDelayNoAdFillOutstream" type="text" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>                         
                    
                    <div class="form-group row">
                        <label for="requestDelayNoAdFillDisplay" class="form-label">Delay no ad fill display <span style="color:white" class="form-label legend"> (ms) </span></label>
                        <!-- ko if: showGlobalAdsBehaviour -->
                        <input data-bind="value: globalAdsBehaviour().requestDelayNoAdFillDisplay" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="requestDelayNoAdFillDisplay" data-bind="value: requestDelayNoAdFillDisplay" type="text" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>     

                    <div class="form-group">
                        <label class="form-sub-title">Request Caps</label>
                    </div>

                    <div class="form-group row">
                        <label for="requestsCapDisplay" class="form-label">Display</label>
                        <!-- ko if: showGlobalAdsBehaviour -->
                        <input data-bind="value: globalAdsBehaviour().requestsCapDisplay" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="requestsCapDisplay" data-bind="value: requestsCapDisplay" type="text" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>   
                    <div class="form-group row">
                        <label for="requestsCapOutstream" class="form-label">Outstream</label>
                        <!-- ko if: showGlobalAdsBehaviour -->
                        <input data-bind="value: globalAdsBehaviour().requestsCapOutstream" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="requestsCapOutstream" data-bind="value: requestsCapOutstream" type="text" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>   

                    <div class="form-group">
                        <label class="form-sub-title">Bid Management</label>
                    </div>
                    
                    <div class="form-group row">
                        <input id="bidManagementEnabled" type="checkbox" data-bind="checked: bidManagementEnabled, attr: {'name': 'bidManagementEnabled' }">
                        <label for="bidManagementEnabled" class="form-label icon i-check">Enable</label>
                    </div>

                    <!--
                    <div data-bind="visible: bidManagementEnabled">
                        <div class="form-group">
                            <label class="form-label">Bid Pools</label>
                        </div>
                        <div data-bind="foreach: bidManagementBidPools">
                            <div class="form-group row">
                                <label class="form-label">Zones</label>
                            </div>
                            <div class="list-header w-justify">
                                <div class="w-left">
                                    <div>
                                        <select multiple style="width: 100%; height: 25rem" data-bind="
                                            options: $parent.allZones,
                                            optionsText: 'name',
                                            optionsValue: 'id',
                                            selectedOptions: zones,
                                        ">
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <button data-bind="event: { click: onClickAddBidPool }" class="btn icon i-add" type="button">Add bid pool</button>
                        </div>
                    </div>
                    -->
                </div>
                <div class="form-body">
                    
                    <div class="form-group">
                        <label class="form-sub-title">Outstream Behaviour</label>
                    </div>
                    
                    <div class="form-group row">
                        <label for="refloatOutstream" class="form-label">Refloat outstream</label>
                        <!-- ko if: showGlobalAdsBehaviour -->
                        <input data-bind="value: globalAdsBehaviour().refloatOutstream" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="refloatOutstream" data-bind="value: refloatOutstream" type="text" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>   
                    <div class="form-group">
                        <label class="form-sub-title">Video ads config Companion</label>
                    </div>
                    <div class="form-group row">
                        <label for="preRollAdsAmountCompanion" class="form-label">Pre-Roll Ads Amount</label>
                        <!-- ko if: showGlobalAdsBehaviour -->
                        <input data-bind="value: globalPreRollAdsAmountCompanion" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="preRollAdsAmountCompanion" data-bind="value: preRollAdsAmountCompanion" type="number" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>                     
                    <div class="form-group row">
                        <label for="midRollAdsAmountCompanion" class="form-label">Mid-Roll Ads Amount</label>
                        <!-- ko if: showGlobalAdsBehaviour -->
                        <input data-bind="value: globalMidRollAdsAmountCompanion" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="midRollAdsAmountCompanion" data-bind="value: midRollAdsAmountCompanion" type="number" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>                      
                    <div class="form-group row">
                        <label for="midRollIntervalMinCompanion" class="form-label">Mid-Roll Interval minimum <span style="color:white" class="form-label legend"> (sec) </span></label>
                        <!-- ko if: showGlobalAdsBehaviour -->
                        <input data-bind="value: globalMidRollIntervalMinCompanion" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="midRollIntervalMinCompanion" data-bind="value: midRollIntervalMinCompanion" type="number" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>                       
                    <div class="form-group row">
                        <label for="midRollIntervalMaxCompanion" class="form-label">Mid-Roll Interval maximum <span style="color:white" class="form-label legend"> (sec) </span></label>
                        <!-- ko if: showGlobalAdsBehaviour -->
                        <input data-bind="value: globalMidRollIntervalMaxCompanion" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="midRollIntervalMaxCompanion" data-bind="value: midRollIntervalMaxCompanion" type="number" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>                        
                    <div class="form-group row">
                        <label for="midRollIntervalMultiplierCompanion" class="form-label">Mid-Roll Interval multiplier</label>
                        <!-- ko if: showGlobalAdsBehaviour -->
                        <input data-bind="value: globalMidRollIntervalMultiplierCompanion" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="midRollIntervalMultiplierCompanion" data-bind="value: midRollIntervalMultiplierCompanion" type="number" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>                      
                    <div class="form-group row">
                        <label for="postRollAdsAmountCompanion" class="form-label">Post-Roll Ads Amount</label>
                        <!-- ko if: showGlobalAdsBehaviour -->
                        <input data-bind="value: globalPostRollAdsAmountCompanion" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="postRollAdsAmountCompanion" data-bind="value: postRollAdsAmountCompanion" type="number" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>         

                    <div class="form-group">
                        <label class="form-sub-title">Video ads config Instream</label>
                    </div>
                    <div class="form-group row">
                        <label for="preRollAdsAmountInstream" class="form-label">Pre-Roll Ads Amount</label>
                        <!-- ko if: showGlobalAdsBehaviour -->
                        <input data-bind="value: globalPreRollAdsAmountInstream" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="preRollAdsAmountInstream" data-bind="value: preRollAdsAmountInstream" type="number" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>                     
                    <div class="form-group row">
                        <label for="midRollAdsAmountInstream" class="form-label">Mid-Roll Ads Amount</label>
                        <!-- ko if: showGlobalAdsBehaviour -->
                        <input data-bind="value: globalMidRollAdsAmountInstream" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="midRollAdsAmountInstream" data-bind="value: midRollAdsAmountInstream" type="number" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>                      
                    <div class="form-group row">
                        <label for="midRollIntervalMinInstream" class="form-label">Mid-Roll Interval minimum <span style="color:white" class="form-label legend"> (sec) </span></label>
                        <!-- ko if: showGlobalAdsBehaviour -->
                        <input data-bind="value: globalMidRollIntervalMinInstream" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="midRollIntervalMinInstream" data-bind="value: midRollIntervalMinInstream" type="number" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>                       
                    <div class="form-group row">
                        <label for="midRollIntervalMaxInstream" class="form-label">Mid-Roll Interval maximum <span style="color:white" class="form-label legend"> (sec) </span></label>
                        <!-- ko if: showGlobalAdsBehaviour -->
                        <input data-bind="value: globalMidRollIntervalMaxInstream" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="midRollIntervalMaxInstream" data-bind="value: midRollIntervalMaxInstream" type="number" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>                        
                    <div class="form-group row">
                        <label for="midRollIntervalMultiplierInstream" class="form-label">Mid-Roll Interval multiplier</label>
                        <!-- ko if: showGlobalAdsBehaviour -->
                        <input data-bind="value: globalMidRollIntervalMultiplierInstream" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="midRollIntervalMultiplierInstream" data-bind="value: midRollIntervalMultiplierInstream" type="number" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>                      
                    <div class="form-group row">
                        <label for="postRollAdsAmountInstream" class="form-label">Post-Roll Ads Amount</label>
                        <!-- ko if: showGlobalAdsBehaviour -->
                        <input data-bind="value: globalPostRollAdsAmountInstream" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="postRollAdsAmountInstream" data-bind="value: postRollAdsAmountInstream" type="number" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>
                    
                    
                    <div class="form-group">
                        <label class="form-sub-title">Home backfill</label>
                    </div>
                    <div class="form-group row">
                        <label for="homeBackfillRefreshTime" class="form-label">Refresh Time <span style="color:white" class="form-label legend"> (ms) </span></label>
                        <!-- ko if: showGlobalAdsBehaviour -->
                        <input data-bind="value: globalAdsBehaviour().homeBackfillRefreshTime"" type="text" class="input-text num read-only" disabled style="width:15%; margin : 0 5px">
                        <!-- /ko -->
                        <input id="homeBackfillRefreshTime" data-bind="value: homeBackfillRefreshTime" type="number" class="input-text num" style="width:15%; margin : 0 5px">
                    </div>
                    <!-- ko if: showGlobalAdsBehaviour -->
                    <div class="form-group row textarea">
                        <label for="homeBackfillLineItemId" class="form-label">Line Item Id</label>
                        <input id="homeBackfillLineItemId" data-bind="value: homeBackfillLineItemId" class="input-text" style=" width:20%; :margin : 0 5px;">
                    </div> 
                    <!-- /ko -->

                </div>
            </div>

            <div class="form-body" style="width: auto">
                <div class="form-group">
                    <label class="form-sub-title">Floors</label>
                    <button data-bind="visible: localPrebidFloors().length > 0, event: { click: onClickResetFloors }" class="btn icon i-remove" type="button">Reset floors</button>
                </div>
                <!-- ko foreach: localPrebidFloors -->
                <div class="form-group row">
                    <label class="form-label">mediaType</label>
                    <input data-bind="value: mediaType" type="text" class="input-text" style="width:15%; margin : 0 5px">
                    <label class="form-label">bidderCode</label>
                    <input data-bind="value: bidderCode" type="text" class="input-text" style="width:15%; margin : 0 5px">
                    <label class="form-label">width</label>
                    <input data-bind="value: width" type="text" class="input-text" style="width:15%; margin : 0 5px">
                    <label class="form-label">height</label>
                    <input data-bind="value: height" type="text" class="input-text" style="width:15%; margin : 0 5px">
                    <label class="form-label">floor</label>
                    <input data-bind="value: floor" type="text" class="input-text" style="width:15%; margin : 0 5px">
                </div> 
                <!-- /ko -->

                <div class="form-group">
                    <button data-bind="event: { click: onClickAddFloor }" class="btn icon i-add" type="button">Add floor</button>
                </div>
            </div> 

            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>
                <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save</span></button>  
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial():void{
        this.pageLoadZones = new multipleSelector_template('zones', this.getTemplateViewModel(), this.caller);
        this.pageLoadZones.setLabel("");
        this.pageLoadZones.setFilterContainerClass('margin-neg');
        this.pageLoadZones.setTagContainerId('selected-zones');
        this.pageLoadZones.setTagContainerCaption('Selected Zones:');
    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "adsBehaviourByBrand-form-template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["w-form", "w-form-large", "margin-top"];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{
        let huit_neuf_this = this;

        return {
            idAdsBehaviour: ko.observable(''),
            idBrand: ko.observable(''),
            pixelDistanceAuctionOtherZones: ko.observable(''),
            viewportPercentageGamRequestDesktop: ko.observable(''),
            viewportPercentageGamRequestMobile: ko.observable(''),
            viewportPercentageGamRenderDesktop: ko.observable(''),
            viewportPercentageGamRenderMobile: ko.observable(''),
            pageLoadZones: ko.observableArray([]),
            refreshLogicRefreshTime: ko.observable(''),
            refreshLogicMinBufferTime: ko.observable(''),
            refreshLogicPrefetch: ko.observable(false),
            requestDelayAdErrorOutstream: ko.observable(''),
            requestDelayNoAdFillOutstream: ko.observable(''),
            requestDelayNoAdFillDisplay: ko.observable(''),
            requestsCapDisplay: ko.observable(''),
            requestsCapOutstream: ko.observable(''),
            bidManagementEnabled: ko.observable(false),
            bidManagementBidPools: ko.observableArray([]),
            refloatOutstream: ko.observable(''),
            preRollAdsAmountCompanion: ko.observable(''),
            midRollAdsAmountCompanion: ko.observable(''),
            midRollIntervalMinCompanion: ko.observable(''),
            midRollIntervalMaxCompanion: ko.observable(''),
            midRollIntervalMultiplierCompanion: ko.observable(''),
            postRollAdsAmountCompanion: ko.observable(''),            
            preRollAdsAmountInstream: ko.observable(''),
            midRollAdsAmountInstream: ko.observable(''),
            midRollIntervalMinInstream: ko.observable(''),
            midRollIntervalMaxInstream: ko.observable(''),
            midRollIntervalMultiplierInstream: ko.observable(''),
            postRollAdsAmountInstream: ko.observable(''),
            homeBackfillRefreshTime: ko.observable(''),     
            homeBackfillLineItemId: ko.observableArray([]), 
            localPrebidFloors: ko.observableArray([]),
            globalPreRollAdsAmountCompanion: ko.observable(''),
            globalMidRollAdsAmountCompanion: ko.observable(''),
            globalMidRollIntervalMinCompanion: ko.observable(''),
            globalMidRollIntervalMaxCompanion: ko.observable(''),
            globalMidRollIntervalMultiplierCompanion: ko.observable(''),
            globalPostRollAdsAmountCompanion: ko.observable(''),            
            globalPreRollAdsAmountInstream: ko.observable(''),
            globalMidRollAdsAmountInstream: ko.observable(''),
            globalMidRollIntervalMinInstream: ko.observable(''),
            globalMidRollIntervalMaxInstream: ko.observable(''),
            globalMidRollIntervalMultiplierInstream: ko.observable(''),
            globalPostRollAdsAmountInstream: ko.observable(''),
            prebidFloors: ko.observableArray([]),
            allZones: ko.observableArray([]),
            defaultZones: ko.observableArray([]),
            selectedDefaultZones: ko.observableArray([]),
            globalAdsBehaviour: ko.observableArray([]),
            showGlobalAdsBehaviour: ko.observable(false),
            onClickSave: function(data, event){ huit_neuf_this.onClickSave(); },
            onClickCancel: function(data, event){ huit_neuf_this.onClickCancel(); },
            onClickAddBidPool: function(data, event){ huit_neuf_this.onClickAddBidPool(); },
            onClickAddFloor: function(data, event){ huit_neuf_this.onClickAddFloor(); },
            onClickResetFloors: function(data, event){ huit_neuf_this.onClickResetFloors(); }
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        let viewModel         = this.getTemplateViewModel();
        let prebidFloorsArray = viewModel.prebidFloors();
        let prebidFloors      = {};

        for(let prebidFloor of prebidFloorsArray){
            
            prebidFloors[prebidFloor.key] = prebidFloor.value;
        }

        return {
            idAdsBehaviour: viewModel.idAdsBehaviour(),
            idBrand: viewModel.idBrand(),
            pageLoadZones: this.pageLoadZones.getTemplateViewModel().selectedItems(),
            pixelDistanceAuctionOtherZones: viewModel.pixelDistanceAuctionOtherZones(),
            viewportPercentageGamRequestDesktop: viewModel.viewportPercentageGamRequestDesktop(),
            viewportPercentageGamRequestMobile: viewModel.viewportPercentageGamRequestMobile(),
            viewportPercentageGamRenderDesktop: viewModel.viewportPercentageGamRenderDesktop(),
            viewportPercentageGamRenderMobile: viewModel.viewportPercentageGamRenderMobile(),
            refreshLogicRefreshTime: viewModel.refreshLogicRefreshTime(),
            refreshLogicMinBufferTime: viewModel.refreshLogicMinBufferTime(),
            refreshLogicPrefetch: viewModel.refreshLogicPrefetch(),
            requestDelayAdErrorOutstream: viewModel.requestDelayAdErrorOutstream(),
            requestDelayNoAdFillOutstream: viewModel.requestDelayNoAdFillOutstream(),
            requestDelayNoAdFillDisplay: viewModel.requestDelayNoAdFillDisplay(),
            requestsCapDisplay: viewModel.requestsCapDisplay(),
            requestsCapOutstream: viewModel.requestsCapOutstream(),
            bidManagementEnabled: viewModel.bidManagementEnabled(),
            bidManagementBidPools: viewModel.bidManagementBidPools(),
            refloatOutstream: viewModel.refloatOutstream(),
            preRollAdsAmountCompanion: viewModel.preRollAdsAmountCompanion(),
            midRollAdsAmountCompanion: viewModel.midRollAdsAmountCompanion(),
            midRollIntervalMinCompanion: viewModel.midRollIntervalMinCompanion(),
            midRollIntervalMaxCompanion: viewModel.midRollIntervalMaxCompanion(),
            midRollIntervalMultiplierCompanion: viewModel.midRollIntervalMultiplierCompanion(),
            postRollAdsAmountCompanion: viewModel.postRollAdsAmountCompanion(),            
            preRollAdsAmountInstream: viewModel.preRollAdsAmountInstream(),
            midRollAdsAmountInstream: viewModel.midRollAdsAmountInstream(),
            midRollIntervalMinInstream: viewModel.midRollIntervalMinInstream(),
            midRollIntervalMaxInstream: viewModel.midRollIntervalMaxInstream(),
            midRollIntervalMultiplierInstream: viewModel.midRollIntervalMultiplierInstream(),
            postRollAdsAmountInstream: viewModel.postRollAdsAmountInstream(),
            homeBackfillRefreshTime: viewModel.homeBackfillRefreshTime(),
            homeBackfillLineItemId: viewModel.homeBackfillLineItemId(),
            prebidFloors: prebidFloors,
            localPrebidFloors: viewModel.localPrebidFloors(),
        };
    }

    public setAdsBehaviourByBrand(adsBehaviourByBrand): void {
        this.getTemplateViewModel().idAdsBehaviour(adsBehaviourByBrand.id);
        this.getTemplateViewModel().idBrand(adsBehaviourByBrand.idBrand);
        this.getTemplateViewModel().pixelDistanceAuctionOtherZones(adsBehaviourByBrand.pixelDistanceAuctionOtherZones);
        this.getTemplateViewModel().viewportPercentageGamRequestDesktop(adsBehaviourByBrand.viewportPercentageGamRequestDesktop);
        this.getTemplateViewModel().viewportPercentageGamRequestMobile(adsBehaviourByBrand.viewportPercentageGamRequestMobile);
        this.getTemplateViewModel().viewportPercentageGamRenderDesktop(adsBehaviourByBrand.viewportPercentageGamRenderDesktop);
        this.getTemplateViewModel().viewportPercentageGamRenderMobile(adsBehaviourByBrand.viewportPercentageGamRenderMobile);
        this.getTemplateViewModel().refreshLogicRefreshTime(adsBehaviourByBrand.refreshLogicRefreshTime);
        this.getTemplateViewModel().refreshLogicMinBufferTime(adsBehaviourByBrand.refreshLogicMinBufferTime);
        this.getTemplateViewModel().requestDelayAdErrorOutstream(adsBehaviourByBrand.requestDelayAdErrorOutstream);
        this.getTemplateViewModel().requestDelayNoAdFillOutstream(adsBehaviourByBrand.requestDelayNoAdFillOutstream);
        this.getTemplateViewModel().requestDelayNoAdFillDisplay(adsBehaviourByBrand.requestDelayNoAdFillDisplay);
        this.getTemplateViewModel().requestsCapDisplay(adsBehaviourByBrand.requestsCapDisplay);
        this.getTemplateViewModel().requestsCapOutstream(adsBehaviourByBrand.requestsCapOutstream);
        this.getTemplateViewModel().refloatOutstream(adsBehaviourByBrand.refloatOutstream);
        this.getTemplateViewModel().homeBackfillRefreshTime(adsBehaviourByBrand.homeBackfillRefreshTime);
        this.getTemplateViewModel().homeBackfillLineItemId(adsBehaviourByBrand.homeBackfillLineItemId);
        if (adsBehaviourByBrand.localPrebidFloors && adsBehaviourByBrand.localPrebidFloors.length > 0) { //if empty we take global config
            this.getTemplateViewModel().localPrebidFloors(adsBehaviourByBrand.localPrebidFloors || []);
        }

        
        //Only set those if it exist
        if (adsBehaviourByBrand.id !== null) {
            this.getTemplateViewModel().bidManagementEnabled(adsBehaviourByBrand.bidManagementEnabled);
            this.getTemplateViewModel().refreshLogicPrefetch(adsBehaviourByBrand.refreshLogicPrefetch);
        }

        if (adsBehaviourByBrand.videoAdsConfigCompanion !== null) {
            this.getTemplateViewModel().preRollAdsAmountCompanion(adsBehaviourByBrand.videoAdsConfigCompanion.preRolls.amount);
            this.getTemplateViewModel().midRollAdsAmountCompanion(adsBehaviourByBrand.videoAdsConfigCompanion.midRolls.amount);
            this.getTemplateViewModel().midRollIntervalMinCompanion(adsBehaviourByBrand.videoAdsConfigCompanion.midRolls.intervalMin);
            this.getTemplateViewModel().midRollIntervalMaxCompanion(adsBehaviourByBrand.videoAdsConfigCompanion.midRolls.intervalMax);
            this.getTemplateViewModel().midRollIntervalMultiplierCompanion(adsBehaviourByBrand.videoAdsConfigCompanion.midRolls.intervalMultiplier);
            this.getTemplateViewModel().postRollAdsAmountCompanion(adsBehaviourByBrand.videoAdsConfigCompanion.postRolls.amount);
        } else {
            this.getTemplateViewModel().preRollAdsAmountCompanion(null);
            this.getTemplateViewModel().midRollAdsAmountCompanion(null);
            this.getTemplateViewModel().midRollIntervalMinCompanion(null);
            this.getTemplateViewModel().midRollIntervalMaxCompanion(null);
            this.getTemplateViewModel().midRollIntervalMultiplierCompanion(null);
            this.getTemplateViewModel().postRollAdsAmountCompanion(null);
        }

        if (adsBehaviourByBrand.videoAdsConfigInstream !== null) {
            this.getTemplateViewModel().preRollAdsAmountInstream(adsBehaviourByBrand.videoAdsConfigInstream.preRolls.amount);
            this.getTemplateViewModel().midRollAdsAmountInstream(adsBehaviourByBrand.videoAdsConfigInstream.midRolls.amount);
            this.getTemplateViewModel().midRollIntervalMinInstream(adsBehaviourByBrand.videoAdsConfigInstream.midRolls.intervalMin);
            this.getTemplateViewModel().midRollIntervalMaxInstream(adsBehaviourByBrand.videoAdsConfigInstream.midRolls.intervalMax);
            this.getTemplateViewModel().midRollIntervalMultiplierInstream(adsBehaviourByBrand.videoAdsConfigInstream.midRolls.intervalMultiplier);
            this.getTemplateViewModel().postRollAdsAmountInstream(adsBehaviourByBrand.videoAdsConfigInstream.postRolls.amount);
        } else {
            this.getTemplateViewModel().preRollAdsAmountInstream(null);
            this.getTemplateViewModel().midRollAdsAmountInstream(null);
            this.getTemplateViewModel().midRollIntervalMinInstream(null);
            this.getTemplateViewModel().midRollIntervalMaxInstream(null);
            this.getTemplateViewModel().midRollIntervalMultiplierInstream(null);
            this.getTemplateViewModel().postRollAdsAmountInstream(null);
        }
        
        let selectedItems = [];
        if (adsBehaviourByBrand.pageLoadZones !== null) {
            for (let i = 0; i < adsBehaviourByBrand.pageLoadZones.length; i++) {
                selectedItems.push({id: adsBehaviourByBrand.pageLoadZones[i]});
            }
        }
        this.pageLoadZones.setSelectedItems(selectedItems);


        let zones = [];
        if (adsBehaviourByBrand.bidManagementBidPools !== null) {
            adsBehaviourByBrand.bidManagementBidPools.forEach(function(bidPool){
                zones.push({zones: ko.observableArray(bidPool)});  
            });
        }
        this.getTemplateViewModel().bidManagementBidPools(zones);

        if(adsBehaviourByBrand['prebidFloors']){

            var prebidFloors = this.getTemplateViewModel().prebidFloors();

            if(!prebidFloors || prebidFloors.length == 0){

                // Price floors.
                Object.keys(adsBehaviourByBrand.prebidFloors).forEach(function(key){
                            
                    prebidFloors.push({key: key, value: ko.observable(adsBehaviourByBrand.prebidFloors[key]), globalValue: ko.observable(null)});
                });
            }
            else{

                let indexedPrebidFloors = [];

                Object.keys(adsBehaviourByBrand.prebidFloors).forEach(function(key){
                    
                    indexedPrebidFloors[key] = adsBehaviourByBrand.prebidFloors[key];
                });

                for(let i = 0; i < prebidFloors.length; i++){

                    prebidFloors[i].value(indexedPrebidFloors[prebidFloors[i].key]);
                }
            }

            this.getTemplateViewModel().prebidFloors(prebidFloors);
        }
    }
    
    public setZones(zones){
        this.getTemplateViewModel().allZones(zones);
        this.pageLoadZones.setItems(zones);
    }

    public setDefaultZones(zones) {
        this.getTemplateViewModel().defaultZones(zones);
    }

    public setGlobalAdsBehaviour(globalAdsBehaviour){
        this.getTemplateViewModel().globalAdsBehaviour(globalAdsBehaviour);
        if (globalAdsBehaviour !== null) {
            this.getTemplateViewModel().showGlobalAdsBehaviour(true);
            this.getTemplateViewModel().globalPreRollAdsAmountCompanion(globalAdsBehaviour.videoAdsConfigCompanion.preRolls.amount);
            this.getTemplateViewModel().globalMidRollAdsAmountCompanion(globalAdsBehaviour.videoAdsConfigCompanion.midRolls.amount);
            this.getTemplateViewModel().globalMidRollIntervalMinCompanion(globalAdsBehaviour.videoAdsConfigCompanion.midRolls.intervalMin);
            this.getTemplateViewModel().globalMidRollIntervalMaxCompanion(globalAdsBehaviour.videoAdsConfigCompanion.midRolls.intervalMax);
            this.getTemplateViewModel().globalMidRollIntervalMultiplierCompanion(globalAdsBehaviour.videoAdsConfigCompanion.midRolls.intervalMultiplier);
            this.getTemplateViewModel().globalPostRollAdsAmountCompanion(globalAdsBehaviour.videoAdsConfigCompanion.postRolls.amount);            
            
            this.getTemplateViewModel().globalPreRollAdsAmountInstream(globalAdsBehaviour.videoAdsConfigInstream.preRolls.amount);
            this.getTemplateViewModel().globalMidRollAdsAmountInstream(globalAdsBehaviour.videoAdsConfigInstream.midRolls.amount);
            this.getTemplateViewModel().globalMidRollIntervalMinInstream(globalAdsBehaviour.videoAdsConfigInstream.midRolls.intervalMin);
            this.getTemplateViewModel().globalMidRollIntervalMaxInstream(globalAdsBehaviour.videoAdsConfigInstream.midRolls.intervalMax);
            this.getTemplateViewModel().globalMidRollIntervalMultiplierInstream(globalAdsBehaviour.videoAdsConfigInstream.midRolls.intervalMultiplier);
            this.getTemplateViewModel().globalPostRollAdsAmountInstream(globalAdsBehaviour.videoAdsConfigInstream.postRolls.amount);
        
            // Prebid floors.
            if(globalAdsBehaviour['prebidFloors']){

                var prebidFloors = this.getTemplateViewModel().prebidFloors();;
    
                // Price floors.
                Object.keys(globalAdsBehaviour.prebidFloors).forEach(function(key){

                    prebidFloors.push({key: key, value: ko.observable(null), globalValue: ko.observable(globalAdsBehaviour.prebidFloors[key])});
                });
    
                this.getTemplateViewModel().prebidFloors(prebidFloors);
            }            
            // Local prebid floors.
            var localPrebidFloors = this.getTemplateViewModel().localPrebidFloors();
            //Only take global config if brandspecific is not saved
            if (localPrebidFloors.length == 0) {
                if (globalAdsBehaviour['localPrebidFloors']) {
                    this.getTemplateViewModel().localPrebidFloors(globalAdsBehaviour.localPrebidFloors);
                }
            }
        }

        //set these values as default. and overwrite them if brandspecific is saved
        this.getTemplateViewModel().refreshLogicPrefetch(globalAdsBehaviour.refreshLogicPrefetch);
        this.getTemplateViewModel().bidManagementEnabled(globalAdsBehaviour.bidManagementEnabled);

        let selectedDefaultZones = [];
        if (globalAdsBehaviour.pageLoadZones !== null) {
            for (let i = 0; i < globalAdsBehaviour.pageLoadZones.length; i++) {
                this.getTemplateViewModel().defaultZones().forEach(function(zone){
                    if (zone.id === globalAdsBehaviour.pageLoadZones[i]) {
                        selectedDefaultZones.push(zone);
                    }
                });
            }
        }
        this.getTemplateViewModel().selectedDefaultZones(selectedDefaultZones);
    }

    /**
     * Handles clicking save button event.
     */
    protected onClickSave(): void{
        this.caller.saveAdsBehaviourByBrand();
    }


    /**
     * Handles clicking cancel button event.
     */
    protected onClickCancel(): void{
        this.caller.cancelAdsBehaviourByBrand();
    }

    /**
     * Handles clicking the add bid pool button event.
     */
    protected onClickAddBidPool(): void{
        this.getTemplateViewModel().bidManagementBidPools.push({zones: []});
    }

    /**
     * Handles clicking the add floor button event.
     */
    protected onClickAddFloor(): void{
        this.getTemplateViewModel().localPrebidFloors.push({mediaType: '', bidderCode: '', width: '', height: '', floor: ''});
    }    /**
     * Handles clicking the reset floor button event.
     */
    protected onClickResetFloors(): void{
        this.getTemplateViewModel().localPrebidFloors([]);
    }
}
