import {vnTemplate} from "../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/vnTemplate";
import { filterDropdown_template } from '../../../vendor/valnet/valnet_jslib/vnKnockoutJsTemplates/filterDropdown_template';

declare var ko: any;

export class adsSetupEdit_template extends vnTemplate{

    protected networkCategoryOption: filterDropdown_template;
    protected contentTypeOption: filterDropdown_template;
    protected templateOption: filterDropdown_template;
    protected adsSetupTagOption: filterDropdown_template;
    protected deviceOption: filterDropdown_template;
    protected sspGroupOption: filterDropdown_template;
    protected geoGroupOption: filterDropdown_template;
    protected groupLabelOption: filterDropdown_template;

    /**
     * @inheritDoc
     */
    protected getTemplateHtml(): string{

        return `
            <div class="form-header">
                <!-- ko ifnot: idAdsSetup -->
                <h3 class="form-title icon i-add">Add New Ads setup</h3>
                <!-- /ko -->

                <!-- ko if: idAdsSetup -->
                <h3 class="form-title icon i-ads">edit Ads setup</h3>
                <!-- /ko -->

                <button data-bind="event: { click: onClickCancel }" class="close-button icon i-close-2" type="button"></button>
            </div>
            <div class="w-form-body"> 
                <div class="form-body">
                
                    <div class="form-group">
                        <legend class="form-legend">active</legend>
                        <div class="form-check-inline width-auto">
                            <input type="radio" name="isActive" id="active_yes" value="1" data-bind="checked: isActive" />
                            <label class="form-label" for="active_yes">Yes</label>
                        </div>
                        <div class="form-check-inline width-auto">
                            <input type="radio" name="isActive" id="active_no" value="0" data-bind="checked: isActive" />
                            <label class="form-label" for="active_no">No</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="name" class="form-label">name</label>
                        <input id="name" data-bind="value: name" type="text" class="input-text">
                     </div>
                     <div class="ads-unit-header full-width border-top">
                        <div class="form-group no-margin">
                            <div class="form-check-inline no-margin"> 
                                <input type="checkbox" data-bind="checked: includeInGroup, attr: {'id': 'includeInGroup', 'name': 'includeInGroup' }">
                                <label class="form-label icon i-check title" data-bind="attr: {'for' : 'includeInGroup' }">Include in group?</label>
                            </div>
                        </div>
                    </div>
                    <div data-bind="visible: includeInGroup">
                        <!--<div class="form-group row">
                            <label class="form-label" for="group">Group</label>
                            <input list="groups" id="group" name="group" data-bind="value: group" disabled/>
                            <datalist id="groups" data-bind="foreach: groups">
                                <option data-bind="value: name"></option>
                            </datalist>
                        </div>-->
                        <div class="form-group row">
                            <label class="form-label">Group Label</label>
                            ${this.groupLabelOption.getHtmlBuild()}
                        </div>
                    </div>

<!--                    <div class="form-group row">-->
<!--                        <label for="route" class="form-label">route</label>-->
<!--                        <input id="route" data-bind="value: route" type="text" class="input-text">-->
<!--                     </div>-->
                     <div class="form-group row">
                        <label for="route" class="form-label">ratio</label>
                        <input id="ratio" title="ratio" placeholder="ratio" data-bind="value: ratio" type="number" class="input-text" min="0" step="1" disabled> 
                     </div>
                     <div class="form-group row">
                        <label class="form-label">Tag</label>
                        ${this.adsSetupTagOption.getHtmlBuild()}
                    </div>
                    <div class="form-group row">
                        <label class="form-label">Device</label>
                        ${this.deviceOption.getHtmlBuild()}
                    </div>
                    <div class="form-group row">
                        <label class="form-label">Template</label>
                        ${this.templateOption.getHtmlBuild()}
                    </div>
                    <div class="form-group row ">
                        <label class="form-label wrap">Network category</label>
                        ${this.networkCategoryOption.getHtmlBuild()}
                    </div>
                    <div class="form-group row">
                        <label class="form-label">Content Type</label>
                        ${this.contentTypeOption.getHtmlBuild()}
                    </div>
                    <!--<div class="form-group row">
                        <label class="form-label">SSP Group</label>
                        ${this.sspGroupOption.getHtmlBuild()}
                    </div>-->
                    <div class="form-group row">
                        <label class="form-label">GEO Group</label>
                        ${this.geoGroupOption.getHtmlBuild()}
                    </div>
                </div>
                 <div class="form-body">
                 
                    <div class="form-group row adding">
                        <label class="form-label full">Geos</label>
                        <button data-bind="click: onClickAddGeo" class="btn icon-only icon i-add" type="button"></button>                       
                    </div>
                    
                    <div data-bind="foreach: geos">
                        <div class="form-group row adding"> 
                           <select data-bind="
                                        options: $parent.allGeos,
                                        optionsText: 'name',
                                        optionsValue: 'id',
                                        value: id
                                    ">
                            </select>
                            <button data-bind="click: $parent.onClickDeleteGeo" class="btn border icon-only icon i-remove" type="button"></button>                      
                        </div>
                    </div>
                    
                    <div class="form-group row adding border-top">
                        <label class="form-label full">Sources</label>
                        <button data-bind="click: onClickAddSource" class="btn icon-only icon i-add" type="button"></button>                      
                    </div>
                    
                    <div data-bind="foreach: sources">
                        <div class="form-group row adding"> 
                          <select data-bind="
                                    options: $parent.allSources,
                                    optionsText: 'name',
                                    optionsValue: 'id',
                                    value: id
                                ">
                            </select>
                            <button data-bind="click: $parent.onClickDeleteSource" class="btn border icon-only icon i-remove" type="button"></button>                    
                        </div>
                    </div>
                    
                    <div class="form-group row adding border-top">
                        <label class="form-label full">Extra parameters</label>
                        <button data-bind="click: onClickAddExtraParameter" class="btn border icon-only icon i-add" type="button"></button>

                    </div>
                    
                    <div data-bind="foreach: extraParameters">
                        <div class="form-group row adding"> 
                            <div class="row">
                                <label class="form-label fix">Name</label>
                                <input data-bind="value: name" type="text" class="input-text">
                            </div>
                            <div class=" row ">
                                <label class="form-label fix">Value</label>
                                <input data-bind="value: value" type="text" class="input-text">
                            </div>
                            <div class=" right">
                                <button data-bind="click: $parent.onClickDeleteExtraParameter" class="btn border icon-only icon i-remove" type="button"></button>
                            </div>
                        </div> 
                    </div>
                </div>   
            </div>
            <div class="form-footer">
                <button data-bind="event: { click: onClickCancel }" class="btn-action icon i-cancel-thin" type="button"><span>Cancel</span></button>
                <button data-bind="event: { click: onClickSave }" class="btn-action icon i-save-thin" type="button"><span>Save Ads Setup</span></button>  
            </div>
        `;
    }

    /**
     * @inheritDoc
     */
    protected initPartial():void{

        this.templateOption        = new filterDropdown_template('templateOption', this.getTemplateViewModel(), this.caller);
        this.networkCategoryOption = new filterDropdown_template('networkCategoryOption', this.getTemplateViewModel(), this.caller);
        this.contentTypeOption     = new filterDropdown_template('contentTypeOption', this.getTemplateViewModel(), this.caller);
        this.adsSetupTagOption     = new filterDropdown_template('adsSetupTagOption', this.getTemplateViewModel(), this.caller);
        this.deviceOption          = new filterDropdown_template('deviceOption', this.getTemplateViewModel(), this.caller);
        this.sspGroupOption        = new filterDropdown_template('sspGroupOption', this.getTemplateViewModel(), this.caller);
        this.geoGroupOption        = new filterDropdown_template('geoGroupOption', this.getTemplateViewModel(), this.caller);
        this.groupLabelOption      = new filterDropdown_template('groupLabelOption', this.getTemplateViewModel(), this.caller);
    }

    /**
     * @inheritDoc
     */
    protected getTemplateName(): string{

        return "adsSetupEdit-form-template";
    }

    /**
     * @inheritDoc
     */
    protected getMainElementClasses(): Array<string>{

        return ["w-form", "w-form-large", "margin-top"];
    }

    /**
     * @inheritDoc
     */
    protected buildViewModel(): any{

        let huit_neuf_this = this;

        return {
            idAdsSetup: ko.observable(''),
            isActive: ko.observable(0),
            name: ko.observable(''),
            route: ko.observable(''),
            idTemplate: ko.observable(''),
            idContentType: ko.observable(''),
            idNetworkCategory: ko.observable(''),
            idSspGroup: ko.observable(''),
            idGeoGroup: ko.observable(''),
            sources: ko.observableArray([]),
            geos: ko.observableArray([]),
            allSources: ko.observableArray([]),
            allGeos: ko.observableArray([]),
            allDevice: ko.observableArray([]),
            extraParameters: ko.observableArray([]),
            ratio: ko.observable(0),
            groups: ko.observableArray([]),
            group: ko.observable(''),
            idGroupLabel: ko.observable(0),
            defaultGroup: ko.observable(''),
            defaulIdGroupLabel: ko.observable(0),
            includeInGroup: ko.observable(false),
            onClickSave: function(data, event){ huit_neuf_this.onClickSave(); },
            onClickCancel: function(data, event){ huit_neuf_this.onClickCancel(); },
            onClickAddExtraParameter: function(data, event){ huit_neuf_this.onClickAddExtraParameter(); },
            onClickDeleteExtraParameter: function(data, event){ huit_neuf_this.onClickDeleteExtraParameter(data); },
            onClickDeleteGeo: function(data, event){ huit_neuf_this.onClickDeleteGeo(data); },
            onClickDeleteSource: function(data, event){ huit_neuf_this.onClickDeleteSource(data); },
            onClickAddSource: function(data, event){ huit_neuf_this.onClickAddSource(); },
            onClickAddGeo: function(data, event){ huit_neuf_this.onClickAddGeo(); },
        };
    }

    /**
     * @inheritDoc
     */
    public getViewModelForRequest(): Object{

        return {
            idAdsSetup: this.getTemplateViewModel().idAdsSetup(),
            name: this.getTemplateViewModel().name(),
            isActive: this.getTemplateViewModel().isActive(),
            route: this.getTemplateViewModel().route(),
            idTemplate: this.templateOption.getTemplateViewModel().selectedOption(),
            idContentType: this.contentTypeOption.getTemplateViewModel().selectedOption(),
            idNetworkCategory: this.networkCategoryOption.getTemplateViewModel().selectedOption(),
            idSspGroup: this.sspGroupOption.getTemplateViewModel().selectedOption(),
            idGeoGroup: this.geoGroupOption.getTemplateViewModel().selectedOption(),
            device: this.deviceOption.getTemplateViewModel().selectedOption(),
            idAdsSetupTag: this.adsSetupTagOption.getTemplateViewModel().selectedOption(),
            sources: this.getTemplateViewModel().sources(),
            geos: this.getTemplateViewModel().geos(),
            extraParameters: this.getTemplateViewModel().extraParameters(),
            ratio: this.getTemplateViewModel().ratio(),
            group: this.getTemplateViewModel().group() || this.getTemplateViewModel().defaultGroup(),
            idGroupLabel: this.groupLabelOption.getTemplateViewModel().selectedOption(),
            includeInGroup: this.getTemplateViewModel().includeInGroup(),
        };
    }

    public setTemplateOptionItems(templates): void{

        templates.unshift({ id: null, name: 'all'});
        this.templateOption.setOptions(templates);
    }

    public setNetworkCategoryOptionItems(networkCategories): void{

        networkCategories.unshift({ id: null, name: 'all'});
        this.networkCategoryOption.setOptions(networkCategories);
    }

    public setContentTypeOptionItems(contentTypes): void{

        contentTypes.unshift({ id: null, name: 'all'});
        this.contentTypeOption.setOptions(contentTypes);
    }

    public setDevicesOptionItems(devices): void{

        this.deviceOption.setOptions(devices);
    }

    public setAdsSetupTagOptionItems(options): void{

        options.unshift({ id: null, name: 'none'});
        this.adsSetupTagOption.setOptions(options);
    }

    public setSspGroupsOptionItems(sspGroups): void{

        sspGroups.unshift({id: null, name: 'no group'});
        this.sspGroupOption.setOptions(sspGroups);
    }

    public setGeoGroupsOptionItems(geoGroups): void{

        this.geoGroupOption.setOptions(geoGroups);
    }

    public setGroupLabelOptionItems(options): void{
        
        this.groupLabelOption.setOptions(options);
    }

    public setGeos(geos): void{

        let geosIds = [];

        for(let geo of geos){

            geosIds.push({'id' : geo.id});

        }

        this.getTemplateViewModel().geos(geosIds);
    }

    public setSources(sources): void{

        let sourcesIds = [];

        for(let source of sources){

            sourcesIds.push({'id' : source.id});

        }

        this.getTemplateViewModel().sources(sourcesIds);

        console.log(this.getTemplateViewModel());
    }


    public setAdsSetup(adsSetup): void{

        this.getTemplateViewModel().name(adsSetup.name);

    }

    /**
     * Handles clicking save button event.
     */
    protected onClickSave(): void{

        this.caller.saveEditAdsSetup();
    }


    /**
     * Handles clicking cancel button event.
     */
    protected onClickCancel(): void{

        this.caller.cancelEditAdsSetup();
    }

    /**
     * Handles add new extra parameter click event.
     */
    protected onClickAddExtraParameter(): void{

        this.getTemplateViewModel().extraParameters.push({name: '', value: ''});
    }

    /**
     * Handles add new extra parameter click event.
     */
    protected onClickDeleteGeo(data): void{
        
        let removedGeo = this.getTemplateViewModel().geos().find(i => i.id === data.id);
        this.getTemplateViewModel().geos.remove(removedGeo);


    }

    /**
     * Handles add new extra parameter click event.
     */
    protected onClickDeleteSource(data): void{

        let removedSource = this.getTemplateViewModel().sources().find(i => i.id === data.id);
        this.getTemplateViewModel().sources.remove(removedSource);

    }

    /**
     * Handles add new extra parameter click event.
     */
    protected onClickDeleteExtraParameter(data): void{

        let removedExtraParameter = this.getTemplateViewModel().extraParameters().find(i => i.name === data.name);
        this.getTemplateViewModel().extraParameters.remove(removedExtraParameter);
    }

    /**
     * Handles add new source click event.
     */
    protected onClickAddSource(): void{

        this.getTemplateViewModel().sources.push({id: this.getTemplateViewModel().allSources()[0].id});

    }

    /**
     * Handles add new extra parameter click event.
     */
    protected onClickAddGeo(): void{

        this.getTemplateViewModel().geos.push({id: this.getTemplateViewModel().allGeos()[0].id});

    }


    protected filterDropdownOnChange(idElement): void{

    }
}
